import {
  envIsIntegration,
  envIsLocal,
  serverEnableRegistrySearch,
  serverEnableWorkspaceTemplates,
} from '../config';
import {RampIdType} from '../generated/graphql';
import {Viewer} from '../state/viewer/types';
import {useViewer} from './../state/viewer/hooks';
import {useBetaFeature} from './useBetaFeature';
import {
  checkEntityRampFlag,
  useEntityRampFlag,
  useOrgNameRampFlag,
  useOrgRampFlag,
  useUserOrOrgRampFlag,
  useUserRampFlag,
} from './useRampFlag';

/*
Docs for ramp feature flags:
https://www.notion.so/wandbai/Ramp-Feature-Flag-Documentation-da56991072df4c8d980cd36804f52ded
*/

/**
 * This is a little hacky but we're wanting to safely prevent special orgs from getting
 * force enrolled in full fidelity until we're ready.
 * 1. The entity name check won't always work because not all workspaces are under namespaces like NVIDIA
 * 2. The `userInfo.company` field is not always populated
 * 3. viewer orgs is pretty reliable, so between all three of these this should be a comprehensive block
 */
// https://weightsandbiases.slack.com/archives/C062UKHTAHG/p1727116018580919
const BLOCK_LIST = ['OPENAI', 'NVIDIA'];
export const getShouldBlockEntity = (
  entityName: string,
  viewer: Viewer | undefined
) => {
  // block special entities
  const isEntityNameBlocked = BLOCK_LIST.includes(entityName.toUpperCase());

  // block based on viewer org
  const isViewerOrgBlocked = viewer?.organizations?.some(org => {
    return BLOCK_LIST.includes(org.name.toUpperCase());
  });

  // block based on userInfo.company
  const company = viewer?.userInfo?.company ?? '';
  const isCompanyBlocked = BLOCK_LIST.includes(company.toUpperCase());

  return isEntityNameBlocked || isViewerOrgBlocked || isCompanyBlocked;
};

export const useRampFlagShadowRSDQ = () => {
  return useUserRampFlag('shadow-rsdq-queries-user', RampIdType.UserName);
};

export const useRampFlagLineConstructionProfiling = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'line-construction-profiling',
    RampIdType.EntityName
  );
};

export const useRampFlagDisableFuzzySearch = (entityName: string) => {
  const rampFlag = useEntityRampFlag(
    entityName,
    'disable-fuzzy-search',
    RampIdType.EntityName
  );
  if (envIsLocal) {
    return true;
  }
  return rampFlag;
};

export const useRampFlagDisableQueryMerging = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'disable-query-merging',
    RampIdType.EntityName
  );
};

export const useRampFlagDisableUpdateHistoryKeysInfo = () => {
  return useUserOrOrgRampFlag(
    'disable-update-history-key-info-user',
    'disable-update-history-key-info-org'
  );
};

export const checkRampFlagEnableOPFSCache = async (
  entityName: string
): Promise<boolean> => {
  return await checkEntityRampFlag(entityName, 'enable-opfs-cache');
};

export const useRampFlagEnableOPFSCache = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'enable-opfs-cache',
    RampIdType.EntityName
  );
};

export const useRampFlagLowResolutionFullFidelityQueries = (
  entityName: string
) => {
  const userOrOrgFlag = useUserOrOrgRampFlag(
    'low-resolution-queries-user',
    'low-resolution-queries-org'
  );

  const entityFlag = useEntityRampFlag(
    entityName,
    'low-resolution-queries-entity',
    RampIdType.EntityName
  );
  return userOrOrgFlag || entityFlag;
};

export const useRampFlagMultiRunsetHistoryKeys = (entityName: string) => {
  const viewer = useViewer();
  const userOrOrgFlag = useUserOrOrgRampFlag(
    'multi-runset-history-keys-user',
    'multi-runset-history-keys-org'
  );

  const entityFlag = useEntityRampFlag(
    entityName,
    'multi-runset-history-keys-entity',
    RampIdType.EntityName
  );

  const shouldBlockEntity = getShouldBlockEntity(entityName, viewer);
  if (shouldBlockEntity) {
    return false;
  }

  return userOrOrgFlag || entityFlag;
};

export const useRampFlagForceFullFidelityInReports = (entityName: string) => {
  const viewer = useViewer();

  const userFlag = useUserRampFlag(
    'force-full-fidelity-in-reports-user',
    RampIdType.UserName
  );

  const entityFlag = useEntityRampFlag(
    entityName,
    'force-full-fidelity-in-reports-entity',
    RampIdType.EntityName
  );

  const shouldBlockEntity = getShouldBlockEntity(entityName, viewer);
  if (shouldBlockEntity) {
    return false;
  }

  return userFlag || entityFlag;
};

export const useRampFlagNonMonotonicXAxis = (entityName: string) => {
  const viewer = useViewer();
  const userOrOrgFlag = useUserOrOrgRampFlag(
    'non-monotonic-x-axis-user',
    'non-monotonic-x-axis-org'
  );
  const entityFlag = useEntityRampFlag(
    entityName,
    'non-monotonic-x-axis-entity',
    RampIdType.EntityName
  );

  return userOrOrgFlag || entityFlag;
};

// in W&B server yet
export const useRampFlagAccountSelector = () => {
  if (envIsLocal) {
    return false;
  }
  return true;
};

export const useRampFlagGlobalRegistry = (orgName: string) => {
  // NB: do not copy this pattern, it is exactly the same as `return useOrgNameRampFlag()`
  const {data: orgGlobalRegistryFlag, loading} = useOrgNameRampFlag(
    orgName,
    'global-registry',
    RampIdType.OrgName
  );
  return {data: orgGlobalRegistryFlag ?? false, loading};
};

export const useRampFlagNoKeysets = () => {
  return useUserOrOrgRampFlag('no-keysets-user', 'no-keysets-org');
};

export const useRampFlagWeaveAsAService = () => {
  const userFlag = useUserRampFlag(
    'weave-as-a-service-user',
    RampIdType.UserName
  );

  // disabling for server
  if (envIsLocal) {
    return false;
  }

  return userFlag ?? false;
};

export const useRampProPricingPlan = () => {
  const userFlag = useUserRampFlag('pro-pricing-plan', RampIdType.UserName);

  // disabling for server
  if (envIsLocal) {
    return false;
  }

  return userFlag ?? false;
};

export const useRampFlagWeaveMarketing = () => {
  const userFlag = useOrgRampFlag('weave-marketing', RampIdType.OrgName);

  // disabling for server
  if (envIsLocal) {
    return false;
  }

  return userFlag;
};

export const useRampProjectSpecificRoles = (orgName: string) => {
  // NB: do not copy this pattern, it is exactly the same as `return useOrgNameRampFlag()`
  const {data: orgProjectSpecificRolesFlag, loading} = useOrgNameRampFlag(
    orgName,
    'project-specific-roles',
    RampIdType.OrgName
  );
  return {data: orgProjectSpecificRolesFlag ?? false, loading};
};

// not all scenarios have an entity name - in those cases, it's
// fine to rely on the org for the flag check
export const useRampFlagMetricChangeNotifications = (
  orgName: string,
  entityName: string
) => {
  const entityEnabled = useEntityRampFlag(
    entityName,
    'run-metric-notifications-entity',
    RampIdType.EntityName
  );

  const orgEnabled = useOrgNameRampFlag(
    orgName,
    'run-metric-notifications',
    RampIdType.OrgName
  );
  if (orgEnabled.loading) {
    return {data: false, loading: true};
  }

  // We OR because the org flag is how we individually pick orgs to enable,
  // and the entity enable is for broadly allowing any paid entity.
  // Right now we don't see a need to disable orgs for this flag.
  return {data: entityEnabled || orgEnabled.data, loading: false};
};

export const useRampFlagStarredProjects = () => {
  return useUserRampFlag('starred-projects', RampIdType.UserName);
};

export const useRampFlagHideSummaryHistograms = (entityName: string) => {
  return useEntityRampFlag(
    entityName,
    'hide-summary-histograms',
    RampIdType.EntityName
  );
};

export const useRampFlagGQLLongTermCache = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'gql-long-term-cache-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'gql-long-term-cache-entity',
    RampIdType.EntityName
  );
  return isUserEnabled || isEntityEnabled;
};

export const useRampFlagAccountSelectorNew = () => {
  return useUserRampFlag('account-selector-new', RampIdType.UserName);
};

export const useRampFlagOrgLevelServiceAccounts = (orgName: string) => {
  return useOrgNameRampFlag(
    orgName,
    'org-level-service-accounts',
    RampIdType.OrgName
  );
};

export const useRampFlagEmbeddedPanels = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'embedded-panels-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'embedded-panels-entity',
    RampIdType.EntityName
  );
  if (envIsLocal || envIsIntegration) {
    return false;
  }
  return isUserEnabled || isEntityEnabled;
};

export const useRampFlagRegistrySearch = (orgName: string) => {
  const isRegistrySearchEnabled = useOrgNameRampFlag(
    orgName,
    'registry-search',
    RampIdType.OrgName
  );
  if (envIsLocal) {
    return {
      data: serverEnableRegistrySearch(),
      loading: false,
    };
  }
  return isRegistrySearchEnabled;
};

export const useRampFlagFormatXAxis = (entityName: string) => {
  const userFlag = useUserRampFlag('format-x-axis-user', RampIdType.UserName);
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'format-x-axis-entity',
    RampIdType.EntityName
  );
  return userFlag || isEntityEnabled;
};

export const useRampFlagBulkMoveRunGroup = (entityName: string) => {
  const isUserOrOrgEnabled = useUserOrOrgRampFlag(
    'bulk-move-run-group-user',
    'bulk-move-run-group-org'
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'bulk-move-run-group-entity',
    RampIdType.EntityName
  );
  if (envIsIntegration) {
    return true;
  }
  return isUserOrOrgEnabled || isEntityEnabled;
};

export const useRampFlagRegistryObserverRole = (orgName: string) => {
  return useOrgNameRampFlag(
    orgName,
    'registry-observer-role',
    RampIdType.OrgName
  );
};

export const useRampFlagWorkspaceTemplates = (entityName?: string) => {
  const viewer = useViewer();
  const userOrOrgFlag = useUserOrOrgRampFlag(
    'workspace-templates-user',
    'workspace-templates-org'
  );
  const entityFlag = useEntityRampFlag(
    entityName,
    'workspace-templates-entity',
    RampIdType.EntityName
  );

  if (envIsIntegration) {
    return false;
  }

  if (envIsLocal) {
    return serverEnableWorkspaceTemplates();
  }

  if (entityName) {
    const shouldBlockEntity = getShouldBlockEntity(entityName, viewer);
    if (shouldBlockEntity) {
      return false;
    }
  }

  return userOrOrgFlag || entityFlag;
};

export const useRampFlagMediaPanelSettingsDrawer = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'media-panel-settings-drawer-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'media-panel-settings-drawer-entity',
    RampIdType.EntityName
  );
  const isBetaFeatureEnabled = useBetaFeature(
    'media-settings-drawer'
  ).isEnabled;
  const viewer = useViewer();
  // We will roll out to openai and nvidia later, block them for now
  const shouldBlockEntity = getShouldBlockEntity(entityName, viewer);
  if (shouldBlockEntity) {
    return false;
  }
  return isBetaFeatureEnabled || isUserEnabled || isEntityEnabled;
};

export const useRampFlagMultiNodeLogs = () => {
  return useUserOrOrgRampFlag(
    'multi-node-log-filtering-user',
    'multi-node-log-filtering-org'
  );
};

export const useRampFlagUseBackendGrouping = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'use-backend-grouping-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'use-backend-grouping-entity',
    RampIdType.EntityName
  );
  return isUserEnabled || isEntityEnabled;
};

export const useRampFlagSemanticLegend = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'semantic-legend-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'semantic-legend-entity',
    RampIdType.EntityName
  );
  return isUserEnabled || isEntityEnabled;
};

export const useRampFlagMediaPanelSliderKey = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'media-panel-slider-key-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'media-panel-slider-key-entity',
    RampIdType.EntityName
  );
  return isUserEnabled || isEntityEnabled;
};

export const useRampFlagUseBackendSmoothing = (entityName: string) => {
  const isUserEnabled = useUserRampFlag(
    'use-backend-smoothing-user',
    RampIdType.UserName
  );
  const isEntityEnabled = useEntityRampFlag(
    entityName,
    'use-backend-smoothing-entity',
    RampIdType.EntityName
  );
  return isUserEnabled || isEntityEnabled;
};

export const useRampFlagWorkspaceCustomRunNames = () => {
  return useUserOrOrgRampFlag(
    'workspace-custom-run-names-user',
    'workspace-custom-run-names-org'
  );
};
